import request from '@/utils/http'

// 项目列表
export function queryList(params) {
  return request({
    url: '/project/pc/list',
    method: 'get',
    params
  })
}

// 获取该租户的工地列表
export function listByTenant(params) {
  return request({
    url: '/project/listByTenant',
    method: 'post',
    data: params
  })
}

// 获取该租户的工地列表
export function updateOrg(params) {
  console.log("params:",params)
  return request({
    url: '/project/updateOrg',
    method: 'post',
    data: params
  })
}






